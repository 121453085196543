<template>
  <div class="popup-list">
    <component
      v-for="(popup, index) in validPopups"
      :is="getPopupStructure(popup.type)?.wrapper"
      :key="index"
      :popup="popup?.data"
      :closeButtonOnLeft="getPopupStructure(popup.type)?.closeButtonOnLeft"
    >
      <component
        :is="getPopupStructure(popup.type)?.innerComponent"
        :data="popup?.data?.data"
        :options="popup?.data?.options"
      />
    </component>
  </div>
</template>

<script setup lang="ts">
import ModalDefault from "./common/popups/ModalDefault.vue";
import TablistMediaSlider from "./blocks/TablistMediaSlider.vue";
import ContentBanner from "./blocks/ContentBanner.vue";
import ArticleBanner2Columns from "./blocks/ArticleBanner2Columns.vue";
import Newsletter from "./blocks/Newsletter.vue";

const popupManager = popupService();

const popupTypeMap = {
  tablist_media_slider: {
    wrapper: ModalDefault,
    innerComponent: TablistMediaSlider,
  },
  content_banner: {
    wrapper: ModalDefault,
    innerComponent: ContentBanner,
  },
  article_2_columns: {
    wrapper: ModalDefault,
    innerComponent: ArticleBanner2Columns,
  },
  newsletter: {
    wrapper: ModalDefault,
    innerComponent: Newsletter,
    closeButtonOnLeft: true,
  },
};

const isValidPopupType = (popup: Record<any, any>) => {
  return !!popup?.type && !!popupTypeMap[popup.type];
};

const validPopups = computed(() => {
  return popupManager.popups.filter((popup) => isValidPopupType(popup));
});

const getPopupStructure = (type: keyof typeof popupTypeMap) => {
  return popupTypeMap[type];
};
</script>
