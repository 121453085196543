<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6422_21758)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.87573 8.2298C4.87573 4.17745 8.14693 0.90625 12.1993 0.90625H12.1985C16.2509 0.90625 19.5221 4.17745 19.5221 8.2298C19.5221 11.6969 14.1558 21.3594 12.8945 23.6305C12.7528 23.8856 12.6629 24.0475 12.6383 24.0968C12.5407 24.2433 12.3942 24.3409 12.1989 24.3409C12.0036 24.3409 11.8571 24.2433 11.7595 24.0968C11.7349 24.0475 11.645 23.8857 11.5034 23.6307C10.2422 21.36 4.87573 11.6974 4.87573 8.2298ZM5.85269 8.22928C5.85269 11.3052 10.7346 20.2395 12.1993 22.8759C13.6637 20.2397 18.5459 11.3049 18.5459 8.22928C18.5459 4.71436 15.7142 1.88269 12.1993 1.88269C8.68437 1.88269 5.85269 4.7141 5.85269 8.22928ZM7.8064 8.22965C7.8064 5.78882 9.75901 3.83594 12.2001 3.83594C14.641 3.83594 16.5938 5.78876 16.5938 8.22965C16.5938 10.6705 14.641 12.6234 12.2001 12.6234C9.75927 12.6234 7.8064 10.6705 7.8064 8.22965ZM8.78231 8.22965C8.78231 10.134 10.2961 11.6475 12.2001 11.6475C14.1044 11.6475 15.6179 10.1338 15.6179 8.22965C15.6179 6.32534 14.1043 4.81186 12.2001 4.81186C10.2958 4.81186 8.78231 6.32549 8.78231 8.22965Z"
        fill="#E89300"
      />
      <circle cx="12.2" cy="7.99902" r="4" fill="#E89300" />
    </g>
    <defs>
      <clipPath id="clip0_6422_21758">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.199951 0.623047)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
