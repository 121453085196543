export function splitTextIntoLines(originalElement: HTMLElement, data: string) {
  const lines: string[] = [];

  if (data) {
    originalElement.innerHTML = data;
  }

  const goToNewLine =(line: string, nextLine: string) => {
    const openers = detectLineTags(line.trim());
    const newLine = `${openers.join("")}${nextLine}`;
    lines.push(line.trim());
    return newLine;
  }

  const wrapper = document.createElement('div');
  wrapper.style.width = getComputedStyle(originalElement).width;
  wrapper.style.visibility = 'hidden';
  wrapper.style.position = 'absolute';
  wrapper.style.display = 'flex';
  wrapper.style.top = '-9999px';
  wrapper.style.whiteSpace = 'nowrap';
  document.body.appendChild(wrapper);

  const clonedContent = originalElement.cloneNode(true);
  wrapper.appendChild(clonedContent);

  const words = String((clonedContent as HTMLElement).innerHTML).replaceAll("\n", "").split(' ');
  let currentLine = '';

  const measureSpan = document.createElement('span');
  measureSpan.style.width = 'fit-content';
  measureSpan.style.flexGrow = '0';
  wrapper.appendChild(measureSpan);

  words.forEach(word => {
    let appliedLine = currentLine ? `${currentLine} ${word}` : word;
    measureSpan.innerHTML = "";
    lines.forEach(line => {
      measureSpan.innerHTML += line + "<br>";
    });
    measureSpan.innerHTML += appliedLine;
    if (measureSpan.offsetWidth >= wrapper.clientWidth) {
      currentLine = goToNewLine(currentLine, word.replaceAll("<br>", ""));
    } else if(appliedLine.includes("<br>")) {
      const parts = appliedLine.split("<br>");
      currentLine = goToNewLine(parts[0], parts[1]);
    } else {
      currentLine = appliedLine;
    }
  });

  if (currentLine) {
    lines.push(currentLine.trim());
  }

  document.body.removeChild(wrapper);

  return lines;
}

export function splitTextIntoLetterElements(originalElement: HTMLElement, data = null, letterClass = 'letter') {
  // If new data is provided, update the element's innerHTML
  if (data) {
    originalElement.innerHTML = data;
  }

  // Recursive function to process each node
  const processNode = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      // Split text node into individual characters and wrap each in a span
      const text: string = node.textContent || "";
      const fragment = document.createDocumentFragment();
      for (let letter of text) {
        if (letter.trim()) {
          const spanOuter = document.createElement('span');
          spanOuter.classList.add(letterClass);
          const span = document.createElement('span');
          span.classList.add('letter-inner');
          span.textContent = letter;
          spanOuter.appendChild(span);
          fragment.appendChild(spanOuter);
        } else {
          // Preserve spaces or newlines
          fragment.appendChild(document.createTextNode(letter));
        }
      }
      (node as HTMLElement).replaceWith(fragment);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // Recursively process child nodes
      Array.from(node.childNodes).forEach(processNode);
    }
  };

  // Clone the original element to work on it without changing the live DOM immediately
  const clonedElement = originalElement.cloneNode(true);

  // Process the cloned content
  processNode(clonedElement);

  // Update the original element with processed content
  originalElement.innerHTML = (clonedElement as HTMLElement).innerHTML;
}

function detectLineTags(text: string) {
  const openers = text.match(/<[^/].*?>/g) || [];
  const closers = text.match(/<\/.*?>/g) || [];
  closers.forEach((closer) => {
    let foundOpenerIndex = -1;
    openers.every((opener, index) => {
      let closetTagName = opener.slice(1).split(" ")[0].replaceAll(">", "");
      if (closer === `</${closetTagName}>`) {
        foundOpenerIndex = index;
        return false;
      }
      return true;
    });
    if (foundOpenerIndex >= 0) {
      openers.splice(foundOpenerIndex, 1);
    }
  });
  return openers;
}