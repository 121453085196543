<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string;
    options: Array<{ name: string; id: string }>;
    disabled?: boolean;
    placeholder?: string;
    initialValue?: string;
    showClear?: boolean;
  }>(),
  {
    disabled: false,
    placeholder: undefined,
    initialValue: undefined,
    showClear: false,
  }
);

const name = toRef(props, "name");

const { value, errorMessage, meta, handleChange } = useField<
  string | undefined
>(name, undefined, {
  initialValue: props.initialValue,
});
</script>
<template>
  <div>
    <Select
      :style="{ fontSize: '1rem', height: '37px', alignItems: 'center' }"
      :name="name"
      :id="name"
      class="w-100"
      :model-value="value"
      @update:model-value="(val : null | string) => handleChange(val ?? undefined)"
      :options="props.options"
      optionLabel="name"
      option-value="id"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      :show-clear="props.showClear"
    />

    <div class="form-field-validation-error">
      <div v-if="!meta.valid">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
