<template>
  <div
    ref="animatedContent"
    class="h-100 w-100"
    :style="{ transform: `scale(${initailScale})` }"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import gsap from "gsap";
const props = withDefaults(
  defineProps<{
    enableAnimation?: boolean;
  }>(),
  {
    enableAnimation: false,
  }
);

const animatedContent = ref<HTMLElement | null>(null);
const initailScale = ref(1);

onBeforeMount(() => {
  if (props.enableAnimation) {
    initailScale.value = 0.75;
  }
});

onMounted(() => {
  let mm = gsap.matchMedia();

  mm.add("(orientation:landscape)", () => {
    gsap.fromTo(
      animatedContent.value,
      { scale: initailScale.value },
      {
        scale: 1,
        duration: 1.6,
        delay: 0.4,
        scrollTrigger: {
          trigger: animatedContent.value,
          start: "top center",
          toggleActions: "play none none none",
        },
      }
    );
  });
});
</script>
