

<template>
  <div class="grid-container">
    <TablistMediaSlider :data="tablistData" :options="{}"></TablistMediaSlider>
  </div>
</template>

<script setup lang="ts">
import TablistMediaSlider from '~/components/blocks/TablistMediaSlider.vue';

const props = defineProps<{
  data: Record<'maps', any[]>;
}>()

const locationsArray = props.data.maps
    .reduce((array, nation) => array.concat(
        nation.regions ? nation.regions.reduce((markers: any, region: any) => markers.concat(region.geographical_area), []) : [],
    ), [])

const setLocations = [];
for (const el of locationsArray) {
  const titles = setLocations.map(e => e.title);
  if (!titles.includes(el.title)) {
    setLocations.push(el);
  }
}
const tablistData = ref(
  {
    slides: [],
    isActive: props.data?.isActive,
  }
);

watch(() => props.data.isActive, (isActive) => {
  tablistData.value.isActive = isActive;
});

setLocations.forEach((location) => {
  if (location.destinations) {
    location.destinations.forEach((destination) => {
      tablistData.value.slides.push({
        title: destination.title,
        content: '<span class="h3-style">' + destination.title + '</span><br>' + '<span class="h3-style fw-500">' + location.title + '</span>',
        background: destination.media,
        actions: destination.cta ? [{cta: destination.cta}] : []
      });
    });
  }
});
</script>
