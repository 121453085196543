<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string;
    disabled?: boolean;
    placeholder?: string;
    initialValue?: string;
  }>(),
  {
    disabled: false,
    placeholder: undefined,
    initialValue: undefined,
  }
);

const name = toRef(props, "name");

const { value, errorMessage, meta, handleChange } = useField<
  string | undefined
>(name, undefined, {
  initialValue: props.initialValue,
});
</script>
<template>
  <div>
    <InputText
      :name="name"
      :id="name"
      class="w-100"
      :model-value="value"
      @update:model-value="handleChange"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
    />

    <div class="form-field-validation-error">
      <div v-if="!meta.valid">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
