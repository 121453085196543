<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string;
    value: string;
    label: string;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const name = toRef(props, "name");

const { value, errorMessage, meta, handleChange } = useField<
  string | undefined
>(name, undefined, {
  initialValue: undefined,
});
</script>
<template>
  <div class="d-flex gap-2 align-items-center">
    <RadioButton
      :id="name"
      :name="name"
      :model-value="value"
      @update:model-value="handleChange"
      :disabled="props.disabled"
      :value="props.value"
    />
    <label :for="props.name" class="form-field-label">{{ props.label }}</label>
  </div>
</template>
